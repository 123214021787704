import { Lang } from 'Roblox';
import angular from 'angular';

const profileAbout = angular
  .module('userDescription', [
    'robloxApp',
    'profileAboutAppTemplates',
    'ui.bootstrap',
    'systemFeedback'
  ])
  .config([
    'languageResourceProvider',
    languageResourceProvider => {
      // conditionally include the languageResourceProvider
      if (Lang.ProfileResources) {
        languageResourceProvider.setLanguageKeysFromFile(Lang.ProfileResources);
      }
    }
  ]);

export default profileAbout;
